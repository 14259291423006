import { useQuery } from "@apollo/client";
import PageContent from "components/PageContent";
import Panel from "components/Panel";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";

import QUERY from "./Query.graphql";
import {
  ExplainWorkbookDetails,
  ExplainWorkbookDetailsVariables,
  ExplainWorkbookDetails_getExplainWorkbookDetails as ExplainWorkbookType,
  ExplainWorkbookDetails_getExplainWorkbookDetails_explainQueries as ExplainQueryType,
} from "./types/ExplainWorkbookDetails";
import Loading from "components/Loading";
import { formatDateMonthDay, formatMs } from "utils/format";
import moment from "moment";
import CreateVariantPanel from "../CreateVariantPanel";
import SelectParameterSets from "../SelectParameterSets";
import PageSecondaryNavigation, {
  PageNavLink,
} from "components/PageSecondaryNavigation";
import { useRoutes } from "utils/routes";
import Grid, { GridColumn } from "components/Grid";
import FilterSearch from "components/FilterSearch";
import { makeFilter } from "utils/filter";
import Popover from "components/Popover";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBolt,
  faFingerprint,
  faTurtle,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import QueryTags, { QueryTagType } from "components/QueryTags";
import { jsonParametersToString } from "./util";

const ExplainWorkbook = () => {
  const { databaseId, workbookId } = useParams();

  const { loading, error, data } = useQuery<
    ExplainWorkbookDetails,
    ExplainWorkbookDetailsVariables
  >(QUERY, {
    variables: { workbookId, databaseId },
  });

  if (loading || error) {
    return <Loading error={!!error} />;
  }

  const workbook = data.getExplainWorkbookDetails;
  const featureNav = (
    <ExplainWorkbookFeatureNav workbook={workbook} databaseId={databaseId} />
  );

  // During the initial workbook creation flow:
  // If the selection is not done yet, show the selection page
  if (!workbook.parameterSetsSelected) {
    return <SelectParameterSets workbook={workbook} />;
  }

  return (
    <PageContent
      windowTitle={`EXPLAIN Workbook: ${workbook.name}`}
      featureInfo={
        <ExplainWorkbookHeader workbook={workbook} showCreateButton />
      }
      pageCategory="explains"
      pageName="workbooks"
      layout="sidebar"
      featureNav={featureNav}
    >
      <ExplainOverviewPanel databaseId={databaseId} workbook={workbook} />
      <ParameterSetsPanel workbook={workbook} />
      {/* sidebar */}
      <ExplainVariantSidebar workbook={workbook} />
    </PageContent>
  );
};

const ExplainOverviewPanel = ({
  databaseId,
  workbook,
}: {
  databaseId: string;
  workbook: ExplainWorkbookType;
}) => {
  const { databaseWorkbookVariant } = useRoutes();
  const [searchTerm, setSearchTerm] = useState("");
  const secondaryTitle = (
    <FilterSearch
      initialValue={searchTerm}
      onChange={setSearchTerm}
      placeholder="Search variants..."
    />
  );

  let fastestRuntime = Infinity;
  let slowestRuntime = 0;

  const variants = [
    workbook.baselineQuery as ExplainQueryType,
    ...workbook.explainQueries,
  ].map((exp) => {
    const dat = {
      id: exp.id,
      name: exp.name,
    } as Record<string, any>;
    exp.explainResults.forEach((val) => {
      const key = `set${val.parameterSetId}`;
      if (val.runtimeMs < fastestRuntime) {
        fastestRuntime = val.runtimeMs;
      }
      if (val.runtimeMs > slowestRuntime) {
        slowestRuntime = val.runtimeMs;
      }
      dat[key] = val.runtimeMs;
    });

    return dat;
  });

  const columns: GridColumn<
    (typeof variants)[number],
    keyof (typeof variants)[number]
  >[] = [
    {
      field: "name",
      header: "Variant",
      renderer: function NameCell({ rowData, fieldData }) {
        return (
          <Link
            to={databaseWorkbookVariant(databaseId, workbook.id, rowData.id)}
          >
            {fieldData}
          </Link>
        );
      },
      width: "1fr",
    },
  ];

  workbook.parameterSets.forEach((val) => {
    columns.push({
      field: `set${val.id}`,
      header: val.name,
      renderer: ({ fieldData }) => {
        if (fieldData == null) {
          return "-";
        }
        const runtime = fieldData as unknown as number;
        let icon: React.ReactNode;
        let runtimeClassName = "";
        if (runtime === fastestRuntime) {
          icon = <FontAwesomeIcon icon={faBolt} title="Fastest" />;
          runtimeClassName = "text-[#2B5827] bg-[#E5F2E6] rounded-md p-0.5";
        }
        if (runtime === slowestRuntime) {
          icon = <FontAwesomeIcon icon={faTurtle} title="Slowest" />;
          runtimeClassName = "text-[#582727] bg-[#F2E5E5] rounded-md p-0.5";
        }
        return (
          <>
            <span className={runtimeClassName}>
              {icon} {formatMs(runtime)}
            </span>
          </>
        );
      },
      width: "150px",
      style: "number",
    });
  });

  const filteredData = variants.filter(makeFilter(searchTerm, "name"));

  return (
    <Panel title="Variants" secondaryTitle={secondaryTitle}>
      <Grid className="grid" data={filteredData} columns={columns} />
    </Panel>
  );
};

export const ExplainWorkbookHeader = ({
  workbook,
  showCreateButton,
}: {
  workbook: ExplainWorkbookType;
  showCreateButton?: boolean;
}) => {
  const [showNewVariantPanel, setShowNewVariantPanel] = useState(false);

  function handleCreateDismiss() {
    setShowNewVariantPanel(false);
  }

  const sideButton = showCreateButton && (
    <button
      className="btn btn-success"
      onClick={() => setShowNewVariantPanel(true)}
    >
      Record New Variant
    </button>
  );

  return (
    <div className="flex items-center">
      <div className="grow">
        <h2 className="text-[#606060] m-0 py-[9px] font-medium leading-[26px] text-[22px]">
          {workbook.name}
        </h2>
        <div>
          {workbook.user?.fullname || "Deleted User"} · Edited{" "}
          {formatDateMonthDay(moment.unix(workbook.lastActivityAt))}
        </div>
      </div>
      <div className="justify-end">{sideButton}</div>
      {showNewVariantPanel && (
        <CreateVariantPanel
          onDismiss={handleCreateDismiss}
          workbook={workbook}
        />
      )}
    </div>
  );
};

export const ExplainWorkbookFeatureNav = ({
  workbook,
  databaseId,
}: {
  workbook: ExplainWorkbookType;
  databaseId: string;
}) => {
  const { databaseWorkbook, databaseWorkbookVariant } = useRoutes();

  return (
    <PageSecondaryNavigation>
      <PageNavLink to={databaseWorkbook(databaseId, workbook.id)}>
        Overview
      </PageNavLink>
      <PageNavLink
        to={databaseWorkbookVariant(
          databaseId,
          workbook.id,
          workbook.baselineQuery.id,
        )}
        end={false}
      >
        Baseline
      </PageNavLink>
      {workbook.explainQueries.map((explainQuery) => {
        return (
          <PageNavLink
            key={explainQuery.id}
            to={databaseWorkbookVariant(
              databaseId,
              workbook.id,
              explainQuery.id,
            )}
            end={false}
          >
            {explainQuery.name}
          </PageNavLink>
        );
      })}
    </PageSecondaryNavigation>
  );
};

export const ExplainVariantSidebar = ({
  workbook,
}: {
  workbook: ExplainWorkbookType;
}) => {
  const { databaseId } = useParams();
  const { databaseQuery } = useRoutes();
  const baselineQuery = workbook.baselineQuery;

  const queryTags: QueryTagType[] = [];
  if (baselineQuery.query) {
    queryTags.push(
      ...[
        {
          id: "fingerprint",
          key: (
            <Popover
              content="A query fingerprint represents the abstracted form of a query and enables the grouping of similar queries together. This value is calculated using the pg_query library."
              popupClassName="!text-[12px]"
            >
              <FontAwesomeIcon
                icon={faFingerprint}
                className="text-[#666] text-[10px]"
              />{" "}
              fingerprint
            </Popover>
          ),
          value: baselineQuery.queryFingerprint,
        },
        {
          id: "role",
          key: (
            <>
              <FontAwesomeIcon
                icon={faUser}
                className="text-[#666] text-[10px]"
              />{" "}
              role
            </>
          ),
          value: baselineQuery.query.postgresRole.name,
        },
      ],
    );
  }

  return (
    <div className="w-[320px]">
      <h4 className="leading-7 mt-0">Description</h4>
      <div className="mb-3">{workbook.description}</div>
      <h4 className="leading-7 mt-0">Baseline Query Info</h4>
      <div className="mb-3">
        {baselineQuery?.query ? (
          <Link to={databaseQuery(databaseId, baselineQuery.query.id)}>
            #{baselineQuery.query.id}
          </Link>
        ) : (
          "No match with existing queries"
        )}
      </div>
      <h4 className="leading-7 mt-0">Query tags</h4>
      <div className="mb-3">
        <QueryTags tags={queryTags} />
      </div>
    </div>
  );
};

export const ParameterSetsPanel = ({
  workbook,
  explainQuery,
}: {
  workbook: ExplainWorkbookType;
  explainQuery?: ExplainQueryType;
}) => {
  const usedAlias = explainQuery
    ? (Object.values(explainQuery.paramRefAliasMap) as string[])
    : null;
  return (
    <Panel title="Parameter Sets" secondaryTitle="Edit parameter set">
      <Grid
        className="grid-cols-[130px_1fr]"
        data={workbook.aliasParamMapList}
        pageSize={5}
        defaultSortBy="id"
        columns={[
          {
            field: "id",
            header: "Name",
            renderer: ({ rowData }) => rowData.name,
          },
          {
            field: "parameters",
            header: "Parameter Set",
            className: "whitespace-normal",
            renderer: function ParametersCell({ fieldData }) {
              return (
                <pre className="border-none m-0 p-0 bg-none bg-transparent whitespace-pre-wrap">
                  {jsonParametersToString(fieldData, null, usedAlias)}
                </pre>
              );
            },
          },
        ]}
      />
    </Panel>
  );
};

export default ExplainWorkbook;
